import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import WhatsAppIcon from "../images/share/whatsapp.svg"
import FacebookIcon from "../images/share/fb.svg"

const Share = () => {
  const { t, i18n } = useTranslation()

  const handleFacebookShareClick = e => {
    e.preventDefault()

    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + process.env.GATSBY_HOST,
      "facebook-share-dialog",
      "width=626, height=436"
    )
  }

  return (
    <div style={{ marginTop: 10 }}>
      <Title>{t("share.title")}</Title>
      <div style={{ display: "flex" }}>
        {/* <div id="fb-root" /> */}
        <FacebookShare
          onClick={handleFacebookShareClick}
          // className="fb-share-button"
          href={process.env.GATSBY_HOST}
          // data-layout="button_count"
        />
        <WhatsAppShare
          href={`https://wa.me/?text=${process.env.GATSBY_HOST}`}
          data-action="share/whatsapp/share"
          target="_blank"
        />
      </div>
    </div>
  )
}

const Title = styled.div`
  margin-bottom: 5px;
`

const WhatsAppShare = styled.a`
  background-image: url("${WhatsAppIcon}");
  height: 25px;
  width: 25px;
  display: block;
`

const FacebookShare = styled.a`
  background-image: url("${FacebookIcon}");
  height: 25px;
  width: 25px;
  display: block;
  margin-right: 10px;
`

export default Share
